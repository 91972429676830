import { Typography } from '@mui/material'
import type { ReactElement } from 'react'

import AppStoreButton from '@/components/common/AppStoreButton'

const PairingDescription = (): ReactElement => {
  return (
    <>
      <Typography variant="caption" align="center">
        Scan this code in the Safe Mobile app to sign transactions with your mobile device.
      </Typography>

      <AppStoreButton placement="pairing" />
    </>
  )
}

export default PairingDescription
