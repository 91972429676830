import type { ReactElement } from 'react'

import { SidebarList } from '@/components/sidebar/SidebarList'
import { ListItem } from '@mui/material'
import DebugToggle from '../DebugToggle'

const SidebarFooter = (): ReactElement => {
  return (
    <SidebarList>
      <ListItem disablePadding>
        <DebugToggle />
      </ListItem>
    </SidebarList>
  )
}

export default SidebarFooter
